// Here you can add other styles
@import "custom_vars";

.breadcrumb-item:not(.active) > * {
   color: $breadcrumb_link_color !important;
 }

table tr {
  input[type=checkbox]:focus {
    border-color: rgba(0, 0, 21, 0.25);
    box-shadow: none !important;
  }
}

button:not(:disabled), [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled) {
  cursor: pointer;
}

.search-form{
  width: 18.5rem;
  height: auto;
  color: var(--cui-sidebar-color, #ffffff);
  background: var(--cui-sidebar-bg, lighten(#292F39, 5));
  box-shadow: none;
}

.search-form-scroll {
  max-height: 79vh;
  overflow: auto !important;
}

.search-form-scroll h4{
  position: sticky;
  top: 0;
  background-color: var(--cui-sidebar-bg, lighten(#292F39, 5));;
  z-index: 1;
  padding-top: 10px !important;
}

.search-form-scroll-btn-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  background-color:  var(--cui-sidebar-bg, lighten(#292F39, 5));
  z-index: 1;
  padding-bottom: 10px;
  margin: 0;
}

.search-form-scroll::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  background-color: transparent;
  margin: 0;
}

.search-form-scroll::-webkit-scrollbar-thumb {
  background-color: #3c4b64;
  border-radius: 0.5em;
  box-shadow: inset 1px 1px 8px rgba(255,255,255, 0.5);
}

.pagination {
  min-width: 240px;
}

.page-link {
  color: var(--cui-sidebar-color, rgba(255, 255, 255));
  background: var(--cui-sidebar-bg,#292F39);
  max-height: 34px;
  padding: 0.25rem 0.375rem;
}

.page-link.unhovered:hover {
  color: var(--cui-sidebar-color, rgba(255, 255, 255));
  background: var(--cui-sidebar-bg, #292F39);
}

.group-select-label {
  display: inline-block;
  width: 2.5em;
  height: 2rem;
}

.group-select-label-wrapper {
  display: flex;
  direction: row;
  flex: 0 0 auto;
  padding: 0.5em;
  height: 3em;
}

.tableDataDropdown{
  width: 25rem;
  overflow-x: auto;
  padding: 1em;
}

.form-tooltip {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: var(--cui-dropdown-color, rgba(44, 56, 74, 0.95));
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 21, 0.15);
  border-radius: 0.25rem;
}

.form-tooltip > li, .cursor-pointer {
  cursor: pointer;
}

// custom table style
.table-wrapper {
  width: auto;
  height: auto;
  margin-bottom: 25px;
  overflow: auto;
  max-height: 80vh;
  position: relative;
}

.resizeable-table {
  width: 100%;
  height: 100%;
  display: grid;
  border-collapse: separate;
}

.resizeable-table thead,
.resizeable-table tbody,
.resizeable-table tr {
  display: contents;
}

.resizeable-table th {
  font-size: 12px;
  text-align: left;
  position: sticky;
  z-index: 1;
  top: 0;
  overflow-wrap: break-word;
  min-width: 25px;
  background-color: #effafa;
  padding: 5px 5px;
  padding-left: 1px;
  max-height: 3rem;
}

.resizeable-table th:first-child {
  padding-left: 5px;
}

.resizeable-table th span {
  max-height: 2.5rem;
  overflow: hidden;
}

.resizeable-table td {
  text-align: left;
  color: #585252;
  padding: 3px;
  min-width: 25px;
  position: relative;
  overflow-wrap: break-word;
  max-height: 5.5rem;
  font-size: 12px;
  line-height: 1.3;
  overflow: hidden;
  background-color: #fff;
}

.resizeable-table .dropdown-toggle::after{
  vertical-align: 0.355em !important;
}

.resizeable-table td.scrollable:hover {
  overflow-y: auto;
}

.resizeable-table tbody tr {
  &:hover:not(.need-attention, .table-row-checked) {
    td {
      background-color: rgba(0 ,0 , 21, 0.075);
      color: rgba(44, 56, 74, 0.95);
    }
  }
}

.resizeable-table tbody tr.need-attention,
.resizeable-table tbody tr.need-attention.odd {
  &:hover:not(.table-row-checked) {
    td {
      background-color: lighten($attention, 2%);
      color: rgba(44, 56, 74, 0.95);
    }
  }
}

.odd > td{
  background: $tr_odd_bg;
}

.resizeable-table tbody .need-attention > td {
  background-color: $attention;
}

.resizeable-table tbody .need-attention.odd > td {
  background-color: darken($attention, 15%);;
}

.resizeable-table tbody .task-free > td {
  background-color: $task_no_operator;
}

.resizeable-table tbody .task-free.odd > td {
  background-color: darken($task_no_operator, 5%);;
}

.resizeable-table tbody .need-attention.table-row-checked > td {
  background-color: darken($attention, 10%);
}

.simple-table tr.need-attention {
  background-color: $attention;
  color: rgba(44, 56, 74, 0.95);
  &:hover:not(.table-row-checked) {
    background-color: lighten($attention, 2%);
  }
  &.table-row-checked {
    background-color: $attention;
  }
}

.simple-table tr.need-attention:nth-child(odd) {
  background-color: darken($attention, 5%);
  &.table-row-checked {
    background-color: $attention;
  }
}

.simple-table tr[data-checkbox], .simple-table td[data-checkbox] {
  padding: 3px;
  width: 15px;
}

.resizeable-table tr td {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid transparent;
}

.table-row-checked:not(.need-attention) > td {
  background-color: darken(#e0e0e0, 10%);
  border-style: dotted !important;
  border-color: #bfb8b8 !important;
}

.table-row-checked + tr > td {
  border-top-color: transparent !important;
}

.resize-handle {
  display: block;
  position: absolute;
  cursor: col-resize;
  width: 7px;
  right: 0;
  top: 0;
  z-index: 2;
  border-right: 2px solid transparent;
}

.resize-handle:hover {
  border-color: #ccc;
}

.resize-handle.active {
  border-color: #517ea5;
}

.resize-handle.static {
  border-right: 1px solid var(--cui-list-group-border-color, rgba(0, 0, 21, 0.125));
}

.truncate-text {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  transform: translateY(6px);
}

.truncate-text::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.avatar-group {
  border: 1px solid #9da5b1;
  height: 10rem;
  position: relative;
}

.avatar-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.avatar-preview {
  display: inline-block;
  height: 9rem;
}

.cancel-avatar {
  position: absolute;
  top: 3px;
  right: 3px;
}

.hide-button {
  position: absolute;
  top: 0.25rem;
  right: calc(50% - 8px);
  color: #f3faf7;
  border: none;
  font-size: 10px;
  box-shadow: none;
  padding: 0;
  background-color: transparent;
  z-index: 2;
}

.hide-button:hover {
  color: #517ea5;
}

.app-link {
  cursor: pointer;
}

.scroll-bar {
  overflow-x: scroll;
  overflow-y: hidden;
  height: 8px;
}

.scroll-bar::-webkit-scrollbar,
table::-webkit-scrollbar,
.scrollable::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  background-color: transparent;
  margin: 0;
}

.scroll-bar::-webkit-scrollbar-thumb,
table::-webkit-scrollbar-thumb,
.scrollable::-webkit-scrollbar-thumb {
  background-color:  #43598a;
  border-radius: 9em;
  box-shadow: inset 1px 1px 10px #f3faf7;
}

.scroll-bar::-webkit-scrollbar-thumb:hover,table::-webkit-scrollbar-thumb:hover {
  background-color: #253861;
}

.bg-child {
  background-color: #e9f5f0;
}

.btn-s {
  background-color: #415069;
}

.date-input {
  font-size: 0.875rem;
  padding: 0.25rem 5px;
  border: 1px solid #b1b7c1;
  border-radius: 3px;
}

.input-icon-wrapper {
  position: absolute;
  top: 0.2rem;
  right: 0;
}

.statistic-cols:not(:first-child) {
  text-align: center;
}

.statistic-cols .item-ceil:not(section .item-ceil) {
  background-color: #e9e9e9aa;
}

.statistic-cols .item-ceil:not(section .item-ceil):last-child {
  font-weight: 600;
  background-color: rgb(237, 227, 227, 0.9) !important;
}

.statistic-head {
  font-size: 14px;
  padding: 5px 5px;
  background-color: #effafa !important;
  font-weight: 600;
  text-align: center;
  z-index: 4;
}

.item-ceil {
  position: relative;
  display: block;
  color: var(--cui-list-group-color, unset);
  color: #585252;
  padding: 5px;
  font-size: 14px;
  background-color: var(--cui-list-group-bg, #fff);
  border-bottom: 1px solid var(--cui-list-group-border-color, rgba(0, 0, 21, 0.125));
}

.item-ceil.statistic-head {
  position: sticky;
  top: 0;
}

.item-ceil span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

.group {
  display: block;
  height: 0;
  max-height: 0;
  opacity: 0;
  line-height: 0;
  transform: scale(0);
  transition: height 0.3s, max-height 0.3s ease-in-out,line-height 0.3s ease-in-out, opacity 0.3s ease-out;
}

.group.show {
  height: auto;
  display: contents;
  opacity: 1;
  line-height: 1.5;
  transform: scale(1);
}

.clickable{
  background-color: aliceblue;
}

.simple-table {
  background-color: #fff;
}

.simple-table thead {
  font-size: 12px;
  line-height: 1.3;
  min-width: 25px;
  max-height: 3rem;
  position: sticky;
  text-align: center;
  vertical-align: top;
  top: 0;
}

.simple-table th {
  background-color: #effafa;
  padding: 3px 5px;
}

.simple-table th:first-child {
  padding-left: 3px !important;
}

.simple-table td {
  text-align: left;
  color: #585252;
  padding: 3px;
  overflow-wrap: break-word;
  font-size: 12px;
  line-height: 1.3;
  overflow: hidden;
}

.simple-table td.truncate {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.simple-table tr td:last-child {
  padding-right: 5px;
}

.simple-table td span.truncate {
  display: inline-block;
  width: inherit;
  word-break: break-all;
  text-overflow: ellipsis;
}

.simple-table .dropdown-toggle::after{
  vertical-align: 0.455em !important;
}

.resizeable-table td ol,
.resizeable-table td span.scrollable,
.simple-table td ul,
.simple-table td span.scrollable,
.simple-table td span.truncate,
.resizeable-table td ul {
  display: block;
  max-height: 4rem;
  overflow: hidden;
  margin-bottom: 2px;
  padding: 0;
}

.simple-table td ul, .resizeable-table td ul {
  list-style: none;
}

.simple-table ul.scrollable:hover,
.resizeable-table td ul:hover,
.resizeable-table td ol:hover,
.simple-table td span.scrollable:hover,
.resizeable-table td span.scrollable:hover {
  overflow-y: auto;
}

.simple-table tr:nth-child(odd) {
    background: #e9e9e9aa;
}

.simple-table tfoot tr {
  font-weight: 600;
  background-color: rgb(237, 227, 227, 0.9) !important;
}

.p-sm {
  padding: 0.15rem!important;
}

.radius-md {
  border-radius: 0.25rem !important;
}

.sidebar-brand {
  display: flex;
  flex: 0 0 3rem;
  align-items: center;
  justify-content: center;
  color: var(--cui-sidebar-brand-color, rgba(255, 255, 255, 0.87));
  background: var(--cui-sidebar-brand-bg, rgba(0, 0, 21, 0.2));
}

.sidebar-nav .nav-group.show {
  background: transparent;
}

.comment-table-wrapper {
  max-height: 10rem;
  overflow: auto;
  border: 1px solid #b1b7c1;
  border-radius: 3px;
}

.comment-table {
  table-layout: fixed;
  width: 100%;
  height: 100%;
  font-size: 12px;
  line-height: 1.3;
  & caption label {
    font-size: 1rem;
    color: #2C384AF2;
  }
  &.radius{
    border-radius: 3px !important;
  }
  tr.marked td{
    background-color: rgba(100,100,100,.175) !important;
  }
  tr td input {
    padding: 0 8px;
    border: none;
    border-radius: 0;
    background-color: transparent;
    font-size: inherit;
    text-align: center;
    &:focus {
      outline: none;
      border: none;
      color: inherit;
      background-color: transparent;
      box-shadow: none;
    }
  }
  caption {
    padding: 0.25rem 0;
  }
  caption .theme-button {
    padding: 0.1rem 1.25rem;
    font-size: 12px;
    width: auto;
    &.delete-button {
      background-color: #da143b;
      border-color: darken(#da143b, 10%);
    }
  }
}

.w-md-auto{
  @media (min-width: 767.98px) {
    width: auto !important;
  }
}

.comment-table th {
  position: sticky;
  text-align: center;
  vertical-align: top;
  top: 0;
  font-weight: 400;
  background: #d8dbe0;
}

.comment-table > :not(caption) > * > * {
  padding: 0.25rem;
}

.btn-input {
  border: 1px solid #b1b7c1;
}

.bth-disabled-style {
  font-size: 14px;
  border-radius: 0;
  color: rgba(44, 56, 74, 0.95);
  font-weight: 600;
  overflow: hidden;
}

.btn-input:focus,
.btn-input:focus.btn-input:active {
    border: 1px solid #b1b7c1;
    box-shadow:none !important;
    outline:0px !important;
}

.btn.bth-disabled-style:focus.dropdown-toggle:active,
.btn.bth-disabled-style:focus.dropdown-toggle:focus {
  border: none;
  box-shadow: none !important;
  outline:0px !important;
  color: #517ea5 !important;
}

.btn.bth-disabled-style.dropdown-toggle:hover {
  color: rebeccapurple !important;
}

.btn-input:disabled {
  border: 1px solid #b1b7c1;
}

.select-menu {  
  font-weight: normal;
  white-space: nowrap;
  min-height: 1.2em;
  font-size: 0.875rem;
  padding: 0.25rem 0.25rem;
  border: 1px solid #b1b7c1;
}

.log-wrapper {
  position: relative;
}

.log-info {
  display: inline-block;
  padding: 0;
  width: 100%;
  overflow-wrap: anywhere;
  max-height: 3rem;
  overflow: hidden;
  text-overflow: elipsis;
  cursor: pointer;
}

.log-details {
  position: absolute;
  z-index: 1000;
  overflow: auto;
  display: none;
  min-width: 6rem;
  max-width: 450px;
  width: 100%;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: var(--cui-dropdown-color, rgba(44, 56, 74, 0.95));
  text-align: start;
  list-style: none;
  background-color: var(--cui-dropdown-bg, #fff);
  background-clip: padding-box;
  border: 1px solid var(--cui-dropdown-border-color, rgba(0, 0, 21, 0.15));
  border-radius: 0.25rem;
}

.log-details pre {
  margin-bottom: 0 !important;
  padding: 0.25rem;
  max-height: 12rem;
}

.log-details.log-details-show {
  display: block;
}

.log-label {
  color: #fff;
}

.log-label:hover {
  cursor: pointer;
  color: #af9f9f;
}

.as-simple-table {
  background-color: #fff;
  min-width: 800px;
  th {
    font-size: 12px;
    line-height: 1.3;
    padding: 3px 5px;
    min-width: 25px;
    background-color: #effafa;
    padding: 5px 5px;
    max-height: 3rem;
    font-weight: 600;
  }
  td {
    text-align: left;
    color: #585252;
    padding: 5px 0 5px 0;
    overflow-wrap: break-word;
    font-size: 12px;
    line-height: 1.3;
    overflow: hidden;
  }
  tr td:last-child {
    padding-right: 5px;
  }
  tbody tr:nth-child(odd) {
    background: #e9e9e9aa;
  }
  .log-wrapper {
    position: static;
  }
  .log-details.log-details-show {
    right: 0;
    top: 0;
  }
}

.accordion-button.disable-box-shadow::after {
  box-shadow:none !important;
  outline:0px !important;
}

.accordion-button:not(.collapsed).disable-box-shadow {
  box-shadow:none !important;
}

.animated-row-group{
  display: block;
  max-height: 0px;
  width: 0;
  margin: 0;
  padding: 0;
  visibility: hidden;
  line-height: 0;
  opacity: 0;
  transform: scale(0);
  transition: max-height 0.4s ease-out, line-height 0.3s ease-in-out;
}

.animated-row-group tr td:first-child {
  padding-left: 15px;
}

.animated-row-group tr {
  background-color: #fff !important;
}

.animated-row-group.show{
  visibility: visible;
  display: table-row-group;
  line-height: 1.5;
  max-height: 50px;
  opacity: 1;
  transform: scale(1);
  transition: max-height 0.4s ease-out, line-height 0.3s ease-in-out, opacity 0.3s ease-out;
}

.rotate {
  transform: rotateY(180deg) !important;
}

.btn-menu-group *.btn {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.btn-menu-group *.btn:nth-child(odd) {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.btn-menu-group > *.btn:last-child{
  margin-right: 0 !important;
}

.btn-menu-group > *.btn:first-child{
  margin-left: 0 !important;
}


.image-details {
  width: 800px;
  height: 800px;
}

.screen-load-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: rgba(0,0,0,0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 30;
}

.notification-toggler {
  color: #fcba03 !important;
}

.form-mini {
  font-size: 14px !important;
  .form-control, .form-select {
    padding: 1px 4px !important;
    font-size: 12px !important;
    border-radius: 3px;
    min-height: initial;
  }
  .form-select {
    background-position: right 0.25rem center;
    background-size: 14px 12px;
  }
}

.custom-dropdown-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 0.65rem !important;
  padding-right: 0.65rem !important;
  span {
    cursor: default;
  }
  &:hover{
    background-color: #DEEBFF;
  }
}

.theme-hover {
  &:hover {
    background-color: #DEEBFF !important;
  }
}

.d-flex {
  &[hidden] {
    display: none !important;
  }
}

.help {
  cursor: default;
}

.task-button-status-toast-wrapper {
  position: relative;
}

.task-button-toast {
  display: none;
}

.task-button-toast.task-button-toast-show {
  display: flex;
  flex-direction: row;
  position: absolute;
  z-index: 1000;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 0.5rem;
  margin: 0;
  font-size: 1rem;
  color: var(--cui-dropdown-color, rgba(44, 56, 74, 0.95));
  text-align: start;
  list-style: none;
  background-color: var(--cui-dropdown-bg, #fff);
  background-clip: padding-box;
  border: 1px solid var(--cui-dropdown-border-color, rgba(0, 0, 21, 0.15));
  border-radius: 0.25rem;
  button {
    position: absolute;
    top: 0;
    right: 2px;
    border: 0;
    padding: 0.5rem;
    background-color: transparent;
    height: 0.25rem;
  }
}

.xxl-1500 {
  @media screen and (min-width: 1600) {
    max-width: 1500;
  }
}

.white-space-no-wrap {
  white-space: nowrap !important;
}

.transition-margin {
  will-change: auto;
  transition: margin .15s;
}

html:not([dir=rtl]) .modal-header .btn-close {
  margin: -6px -2px 0 0;
}

.max-height-unset {
  max-height: none;
}

.max-width-unset {
  min-width: 100%;
}

.lh-medium {
  line-height: 1.75 !important;
}

.fs-7 {
  font-size: 0.75rem !important;
}

.sidebar-narrow .sidebar-logo {
  transform: translateX(-40.2%);
}

.rating {
	display: inline-block;
	font-size: 0;
}
.rating span {
	padding: 0;
	font-size: 16px;
	line-height: 1;
	color: gold;
}
.rating > span:before {
	content: '★';
}

.transition-translate {
  will-change: auto;
  transition: transform .25s ease-in-out;
}
   
.translate-left {
  transform: translateX(calc(-100% - 2.5rem));
}
   
.translate-right {
  transform: translateX(100%);
}
   
.flex-width-100 {
  flex: 0 0 100%;
}
  
.flex-stub {
  flex: 0 0 2.5rem;
}

.hiddenDocs {
  display: none;
}
